import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./SignupForm.module.css";
import CryptoJS from "crypto-js";
import { Buffer } from "buffer";
import CopyToClipboardButton from "../components/CopyToClipboardButton.jsx";
import Countdown from "../components/Countdown";

function SignupForm({ routePath = "" }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let referValue = queryParams.get("refer");

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const REFERRAL_LIST_ID = "VGdNRP"; // MAXI - Referrals list

  // Create a new Date object for June 30, 2024, at 11:59 PM
  const endDate = new Date("2024-06-30T23:59:59");

  // Get the timestamp for this date and time
  const finalTimestamp = endDate.getTime();

  if (referValue === null || referValue === undefined || referValue === "") {
    // if (routePath === "") {
    //   referValue = "instagram";
    // } else {
    //   referValue = routePath;
    // }
    return <p>The page you're looking for cannot not found.</p>;
  }

  console.log("REFER:", referValue);
  const isValidEmail = (email) => {
    // Regular expression pattern for validating email format
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(email);
  };

  /* Encode the email to a 8 character encoded string */
  const encodeEmail = (email) => {
    const hash = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
    return Buffer.from(hash).toString("base64").substring(0, 8); // Taking only first 8 characters
  };

  /* Remove white space and convert email to lowercase */
  const handleEmailChange = (e) => {
    const cleansedValue = e.target.value.toLowerCase().trim();
    setEmail(cleansedValue);
  };

  const submitForm = async () => {
    setError(null);

    // Check if input fields are empty
    if (!email || !firstName || !lastName) {
      setError("Please fill in all fields.");
      return; // exit the function
    }

    // Check if email is valid
    if (!isValidEmail(email)) {
      setError("Please enter a valid email.");
      return; // exit the function
    }

    setIsLoading(true);

    // First we send the data to our custom server
    // Define the data to be sent in the POST request
    const postData = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      referrer: referValue,
    };

    try {
      const response1 = await fetch(
        "https://vehla-production.up.railway.app/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      // Check if the request was successful
      if (!response1.ok) {
        setIsLoading(false);
        if (response1.status === 429) {
          setError("Please try again later.");
        }
        if (response1.status === 403) {
          setError(
            "Good news! You have already entered our giveaway. An email containing your referral link should be already in your inbox."
          );
        } else {
          setError("An error occurred.");
        }
        return;
      }

      //Now subscribe the user to the list

      const klaviyoOptions = {
        method: "POST",
        headers: { revision: "2023-07-15", "content-type": "application/json" },
        body: JSON.stringify({
          data: {
            type: "subscription",
            attributes: {
              custom_source: "Maxi Giveaway contest",
              profile: {
                data: {
                  type: "profile",
                  attributes: {
                    email: email,
                    first_name: firstName,
                    last_name: lastName,
                    properties: { EncodedEmail: encodeEmail(email) },
                  },
                },
              },
            },
            relationships: {
              list: { data: { type: "list", id: REFERRAL_LIST_ID } },
            },
          },
        }),
      };

      const response2 = await fetch(
        "https://a.klaviyo.com/client/subscriptions/?company_id=NQbyBy",
        klaviyoOptions
      );

      // Check if the request was successful
      if (response2.ok) {
        setIsLoading(false);
        setIsDone(true);
      }
    } catch (error) {
      setError("An unexpected error occured.");
      console.error("There was an error with the fetch request:", error);
    }
  };
  // Return giveaway closed message
  return (
    <body>
      <div className={styles.container}>
        <img
          src="https://cdn.shopify.com/s/files/1/0082/8339/0014/files/Saint_Laurent_Maxi_bag.png"
          id={styles["bag-img"]}
          alt="Saint Laurent Maxi Bag"
        />
        <>
          {isLoading ? (
            <div className={styles["loading-spinner"]}></div>
          ) : (
            <div className={styles["width-container"]}>
              <h1>WIN 2 x SAINT LAURENT MAXI SHOPPING BAGS</h1>
              <h1 id={styles["max-value"]}>VALUED AT $13,040</h1>
              <p id={styles["w-100"]}>
                Giveaway closed, winner will be announced via email.
              </p>
            </div>
          )}
        </>
      </div>
    </body>
  );

  return (
    <body>
      <div className={styles.container}>
        <img
          src="https://cdn.shopify.com/s/files/1/0082/8339/0014/files/Saint_Laurent_Maxi_bag.png"
          id={styles["bag-img"]}
          alt="Saint Laurent Maxi Bag"
        />
        <>
          {isLoading ? (
            <div className={styles["loading-spinner"]}></div>
          ) : !isDone ? (
            <div className={styles["width-container"]}>
              <h1>WIN 2 x SAINT LAURENT MAXI SHOPPING BAGS</h1>
              <h1 id={styles["max-value"]}>VALUED AT $13,040</h1>
              <Countdown date={finalTimestamp} />
              {error && (
                <div id={styles["error-box"]}>
                  <p id={styles["error-msg"]}>{error}</p>
                </div>
              )}

              <div id={styles["subscribe-form"]}>
                <div id={styles["name-container"]}>
                  <input
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="FIRST NAME"
                  />
                  <input
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="LAST NAME"
                  />
                </div>
                <input
                  type="text"
                  onChange={handleEmailChange}
                  placeholder="EMAIL"
                />
                <button onClick={submitForm}>ENTER NOW</button>
              </div>
              <p id={styles["info-text"]}>
                The winner will receive two identical bags and can choose to
                share the prize with a friend or keep both.
              </p>
              <a
                href="https://vehlaeyewear.com/pages/competition-terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                id={styles["policies"]}
              >
                Full terms and conditions
              </a>
            </div>
          ) : (
            <div id={styles["confirmation-screen"]}>
              <p>
                Congratulations, you have successfully entered our giveaway.
              </p>
              <p id={styles["winner-announce"]}>
                We will be announcing the winner on the 4th July 2024 via email.
              </p>
              <p id={styles["want-to"]}>SHARE TO WIN</p>
              <p>
                Copy the link below and share it within your network, via group
                chats and social media. For every person who signs up through
                your referral, you'll earn an additional entry into the
                giveaway. Good luck!
              </p>
              <CopyToClipboardButton
                text={`https://vehla.com/giveaway?refer=${encodeEmail(email)}`}
              />
            </div>
          )}
        </>
      </div>
    </body>
  );
}

export default SignupForm;
